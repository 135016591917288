import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { content, navigation, navList, navItem, list } from './how-do-we-work.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IWorkStep } from '../models/work-step.model';
import { getColorInSequence } from '../utils/get-color-in-sequence';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import WorkStepCard, { IWorkStepCardProps } from '../components/molecules/work-step-card';
import WorkStepNavItem from '../components/atoms/work-step-nav-item';

interface IHowDoWeWorkPageProps {
    readonly data: {
        allWorkStep: IQueryAllResult<IWorkStep>;
    };
}

const HowDoWeWorkPage: React.FC<IHowDoWeWorkPageProps> = ({ data }) => {
    const { t } = useT();
    const { allWorkStep } = data;
    const workSteps = getNodes(allWorkStep);
    const [visibleStepsIds, setVisibleStepsIds] = useState([workSteps[0]?.workStepId]);

    const handleWorkStepIntersect: IWorkStepCardProps['onIntersect'] = (workStepId, entry) => {
        setVisibleStepsIds((prevVisibleSteps) => {
            let newVisibleSteps = [...prevVisibleSteps];
            if (entry.isIntersecting) {
                if (prevVisibleSteps.includes(workStepId)) return newVisibleSteps;
                newVisibleSteps = [...prevVisibleSteps, workStepId];
            } else {
                newVisibleSteps = prevVisibleSteps.filter(
                    (visibleStepId) => visibleStepId !== workStepId
                );
            }
            return newVisibleSteps;
        });
    };

    return (
        <MainLayout showFloatingCircles={true}>
            <Hero
                titleProps={{ Tag: 'h1', children: t('how.hero.title') }}
                description={t('how.hero.description')}
                circleDisplay="color"
                color="blue"
            />
            {workSteps.length > 0 && (
                <Section
                    circleDisplay="none"
                    height="auto"
                    theme="light"
                    contentDisplay="left"
                    gridType="narrow"
                >
                    <div className={content}>
                        <nav className={navigation}>
                            <ol className={navList}>
                                {workSteps.map((workStep, index) => {
                                    const isActive = visibleStepsIds?.[0] === workStep.workStepId;
                                    const color = getColorInSequence(index);
                                    return (
                                        <WorkStepNavItem
                                            isActive={isActive}
                                            workStep={workStep}
                                            color={color}
                                            className={navItem}
                                            key={`work-step-nav-item-${workStep.workStepId}`}
                                        />
                                    );
                                })}
                            </ol>
                        </nav>
                        <ol className={list}>
                            {workSteps.map((workStep, index) => {
                                const color = getColorInSequence(index);
                                return (
                                    <li key={`work-step-item-${workStep.workStepId}`}>
                                        <WorkStepCard
                                            workStep={workStep}
                                            color={color}
                                            onIntersect={handleWorkStepIntersect}
                                        />
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                </Section>
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query HowDoWeWorkPage($locale: String!) {
        allWorkStep(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...workStepFields
                }
            }
        }
    }
`;

export default HowDoWeWorkPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
