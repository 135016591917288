import React from 'react';

import { link, yellow, purple, pink, blue, active } from './work-step-nav-item.module.scss';
import { IWorkStep } from '../../models/work-step.model';
import { TAccentColor } from '../../models/accent-color.model';

interface IWorkStepNavItemProps {
    className?: string;
    workStep: IWorkStep;
    color?: TAccentColor;
    isActive?: boolean;
}

const WorkStepNavItem: React.FC<IWorkStepNavItemProps> = ({
    className = '',
    workStep,
    color = 'purple',
    isActive = false,
}) => {
    const colorClass = colorClasses[color];

    return (
        <li className={`${link} ${className} ${colorClass} ${isActive ? active : ''}`}>
            <a href={`#${workStep.title}`}>{workStep.title}</a>
        </li>
    );
};

const colorClasses: Record<TAccentColor, string> = {
    yellow,
    purple,
    pink,
    blue,
};

export default WorkStepNavItem;
