import React, { useEffect, useRef } from 'react';

import { container, circle, titleText } from './work-step-card.module.scss';
import { IWorkStep } from '../../models/work-step.model';
import { TAccentColor } from '../../models/accent-color.model';

import Markdown from '../hoc/markdown';
import Text from '../atoms/text';
import Semicircle from '../atoms/semicircle';

export interface IWorkStepCardProps {
    className?: string;
    color?: TAccentColor;
    workStep: IWorkStep;
    TitleTag?: React.ElementType;
    onIntersect?(workStepId: number, entry: IntersectionObserverEntry): void;
}

const WorkStepCard: React.FC<IWorkStepCardProps> = ({
    className = '',
    color = 'purple',
    workStep,
    TitleTag = 'h2',
    onIntersect,
}) => {
    const { title, description } = workStep;
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!containerRef.current) return;
        const container = containerRef.current;
        const handleIntersect: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (typeof onIntersect !== 'function') return;
                onIntersect(workStep.workStepId, entry);
            });
        };
        const observer = new IntersectionObserver(handleIntersect, {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        });
        observer.observe(container);
        return () => {
            if (observer) {
                observer.unobserve(container);
            }
        };
    }, [onIntersect, workStep.workStepId]);

    return (
        <div ref={containerRef} id={title} className={`${container} ${className}`}>
            <div>
                <Semicircle
                    className={circle}
                    color={color}
                    orientation="left"
                    containerType="half"
                />
            </div>
            <div>
                <TitleTag className={titleText}>{title}</TitleTag>
                {description && (
                    <Text Tag="div">
                        <Markdown>{description}</Markdown>
                    </Text>
                )}
            </div>
        </div>
    );
};

export default WorkStepCard;
